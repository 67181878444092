<template>
  <Form
    style="width: 100%"
    @submit="onSubmitSearch"
    id="kt_account_profile_details_form"
    class="form"
    novalidate="novalidate"
  >
    <div class="row">
      <div class="col-9">
        <Datepicker
          locale="th"
          placeholder="เลือกวัน"
          range
          :enableTimePicker="false"
          :format-locale="th"
          cancelText="ยกเลิก"
          selectText="เลือก"
          v-model="dateST"
        >
        </Datepicker>
      </div>
      <div class="col-3">
        <button
          type="submit"
          style="width: 100%"
          ref="submitButton"
          class="btn btn-primary"
        >
          <span class="indicator-label"> เรียกดู </span>
        </button>
      </div>
    </div>
    <Field type="hidden" name="dateST" v-model="dateST" />
  </Form>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { th } from "date-fns/locale";
import { defineComponent, ref } from "vue";
import { Form, Field } from "vee-validate";
import useSweetalert from "@/core/helpers/sweetalert2";
export default defineComponent({
  name: "appeal_wait",
  components: {
    Form,
    Field,
    Datepicker,
  },
  setup(props, { emit }) {
    const dateST = ref();
    const { SconfirmNc } = useSweetalert();
    const onSubmitSearch = (val) => {
      if (val?.dateST) {
        if (val?.dateST?.[0] && val?.dateST?.[1]) {
          emit("onFilter", val.dateST);
        } else {
          SconfirmNc(
            "กรุณาระบุวันที่ให้ถูกต้อง",
            "error",
            "ตกลง"
          );
        }
      } else {
        emit("onFilter", val.dateST);
      }
    };

    return {
      onSubmitSearch,
      dateST,
      th,
    };
  },
});
</script>

<style></style>
