<template>
  <div class="vld-parent col-xl-3">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Statistics Widget 5-->
    <a href="#" class="card bg-dark hoverable card-xl-stretch mb-xl-8">
      <!--begin::Body-->
      <div class="card-body" v-if="register_province >= 0">
        <!--begin::Svg Icon | path: icons/duotone/Communication/Group.svg-->
        <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
          <i class="bi bi-people-fill text-white"></i>
        </span>

        <!--end::Svg Icon-->
        <div class="text-inverse-dark fw-bolder fs-2 mb-2 mt-5">
          {{ register_province }} คน
        </div>
        <div class="fw-bold text-inverse-dark" style="font-size: 15px">
          เป็นคนในจังหวัดแม่ฮ่องสอน
        </div>
      </div>
      <div class="card-body err" v-else></div>
      <!--end::Body-->
    </a>
    <!--end::Statistics Widget 5-->
  </div>
</template>

<script>
import useReport_all from "@/core/services/api/reports/report_all";
import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
  setup() {
    const { getWidgetRegisterProvince } = useReport_all();
    const register_province = ref();
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      getWidgetRegisterProvince().then((response) => {
        register_province.value = response.data.data;
        isLoading.value = false;
      });
    });

    return {
      isLoading,
      color,
      register_province,
    };
  },
});
</script>

<style scoped>
.err {
  height: 165.5px;
}
.bi {
  font-size: 40px;
}
</style>
