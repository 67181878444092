<template>
  <div class="vld-parent col-8">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Charts Widget 1-->
    <div class="card card-xl-stretch mb-xl-8">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <!--begin::Title-->
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">ตำบลในจังหวัดแม่ฮ่องสอน</span>
        </h3>
        <!--end::Title-->
      </div>
      <!--begin::Body-->
      <div class="card-body">
        <apexchart type="bar" :options="options" :series="series"></apexchart>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Charts Widget 1-->
  </div>
</template>

<script>
import useReport_all from "@/core/services/api/reports/report_all";

import { defineComponent, onMounted, ref, computed } from "vue";
export default defineComponent({
  setup() {
    const { getChartDistrict } = useReport_all();
    const array_num_man = ref([]);
    const array_num_woman = ref([]);
    const label = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      getChartDistrict().then((response) => {
        array_num_man.value = response.data.array_num_man;
        array_num_woman.value = response.data.array_num_woman;
        label.value = response.data.labels;

        isLoading.value = false;
      });
    });

    const options = computed(() => {
      let options = {
        labels: label.value,
        colors: ["#009ef7", "#ff6384"],
      };
      return options;
    });

    const series = computed(() => {
      let num_man = array_num_man.value;
      let num_woman = array_num_woman.value;
      let series = [{name: 'ชาย', data: num_man }, { name: 'หญิง',data:num_woman }];
      return series;
    });

    return {
      isLoading,
      color,
      options,
      series,
    };
  },
});
</script>

<style scoped>
.err {
  height: 165.5px;
}
.bi {
  font-size: 40px;
}
</style>
