<template>
  <div class="vld-parent col-xl-4">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>

    <!--begin::List Widget 1-->
    <div class="card card-xl-stretch mb-xl-8">
      <!--begin::Body-->
      <div class="card-body p-0">
        <!--begin::Header-->
        <div class="px-9 pt-7 card-rounded h-275px w-100 bg-warning">
          <!--begin::Heading-->
          <div class="d-flex flex-stack">
            <h3 class="m-0 text-white fw-bolder fs-3">ใบงาน ดำเนินการ</h3>
          </div>
          <!--end::Heading-->
          <!--begin::Balance-->
          <div class="d-flex text-center flex-column text-white pt-8">
            <span class="fw-bold fs-7">จำนวน</span>
            <span class="fw-bolder fs-2x pt-1">{{ total }}</span>
            <span class="fw-bold fs-7">ใบงาน</span>
          </div>
          <!--end::Balance-->
        </div>
        <!--end::Header-->
        <!--begin::Items-->
        <div
          class="
            shadow-xs
            card-rounded
            mx-9
            mb-9
            px-6
            py-9
            position-relative
            z-index-1
            bg-white
            overflow-auto
          "
          style="margin-top: -100px; height: 381px"
        >
          <!--begin::Item-->
          <div
            v-for="title_appeal in title_appeals"
            :key="title_appeal.id"
            class="d-flex align-items-center mb-6"
          >
            <!--begin::Symbol-->
            <div class="symbol symbol-45px w-40px me-5">
              <span class="symbol-label bg-lighten">
                <!--begin::Svg Icon | path: icons/duotone/Home/Globe.svg-->
                <img alt="Logo" :src="title_appeal.icon" class="h-40px" />
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Description-->
            <div class="d-flex align-items-center flex-wrap w-100">
              <!--begin::Title-->
              <div class="mb-1 pe-3 flex-grow-1">
                <a class="fs-5 text-gray-800 text-hover-primary fw-bolder">{{
                  title_appeal.title
                }}</a>
              </div>
              <!--end::Title-->
              <!--begin::Label-->
              <div class="d-flex align-items-center">
                <div class="fw-bolder fs-5 text-gray-800 pe-1">
                  {{ title_appeal.num }}
                </div>
              </div>
              <!--end::Label-->
            </div>
            <!--end::Description-->
          </div>
          <!--end::Item-->
        </div>
        <!--end::Items-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::List Widget 1-->
  </div>
</template>

<script>
import useReport_all from "@/core/services/api/reports/report_all";
import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
  setup() {
    const { getWidgetAppealProcessing } = useReport_all();
    const total = ref();
    const title_appeals = ref();
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      getWidgetAppealProcessing().then((response) => {
        total.value = response.data.total;
        title_appeals.value = response.data.title_appeals;
        isLoading.value = false;
      });
    });

    function Filter(value) {
      isLoading.value = true;
      getWidgetAppealProcessing(value).then((response) => {
        total.value = response.data.total;
        title_appeals.value = response.data.title_appeals;
        isLoading.value = false;
      });
    }

    return {
      isLoading,
      color,
      total,
      title_appeals,
      Filter,
    };
  },
});
</script>

<style scoped>
.err {
  height: 165.5px;
}
.bi {
  font-size: 40px;
}
</style>
