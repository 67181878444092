<template>
  <div class="row g-5 g-xl-8 mb-5">
    <div class="col-12">
      <!--begin::Charts Widget 1-->
      <div class="card card-xl-stretch mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <!--begin::Title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">รายชื่อลงทะเบียน</span>
          </h3>
          <!--end::Title-->
          <!--begin::Toolbar-->
          <div class="card-toolbar"></div>
          <!--end::Toolbar-->
        </div>
      </div>
      <!--end::Charts Widget 1-->
    </div>
  </div>
  <!--end::Row-->
  <!--begin::Row-->
  <div class="row g-5 g-xl-8 mb-5">
    <WidgetRegister></WidgetRegister>
    <WidgetRegisterProvince></WidgetRegisterProvince>
    <WidgetRegisterAmphoe></WidgetRegisterAmphoe>
    <WidgetRegisterProvinceOther></WidgetRegisterProvinceOther>
  </div>
  <div class="row g-5 g-xl-8 mb-5">
    <GenderChart></GenderChart>
    <DistrictChart></DistrictChart>
  </div>

  <div class="row g-5 g-xl-8 mb-5">
    <div class="col-12">
      <!--begin::Charts Widget 1-->
      <div class="card card-xl-stretch mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <!--begin::Title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">ร้องเรียน</span>
          </h3>
          <!--end::Title-->
          <!--begin::Toolbar-->
          <div class="card-toolbar" style="width: 375px">
            <Filter @onFilter="onFilter"></Filter>
          </div>
          <!--end::Toolbar-->
        </div>
      </div>
      <!--end::Charts Widget 1-->
    </div>
  </div>
  <!--end::Row-->
  <div class="row g-5 g-xl-8 mb-5">
    <WidgetTotal ref="getNewTotal"></WidgetTotal>
    <WidgetSuccess ref="getNewSuccess"></WidgetSuccess>
  </div>
  <div class="row g-5 g-xl-8 mb-5">
    <WidgetWait ref="getNewWait"></WidgetWait>
    <WidgetReceive ref="getNewReceive"></WidgetReceive>
    <WidgetProcessing ref="getNewProcessing"></WidgetProcessing>
  </div>
</template>

<script>
import WidgetRegister from "@/components/reports/widget/WidgetRegister.vue";
import WidgetRegisterProvince from "@/components/reports/widget/WidgetRegisterProvince.vue";
import WidgetRegisterAmphoe from "@/components/reports/widget/WidgetRegisterAmphoe.vue";
import WidgetRegisterProvinceOther from "@/components/reports/widget/WidgetRegisterProvinceOther.vue";
import GenderChart from "@/components/reports/chart/GenderChart.vue";
import DistrictChart from "@/components/reports/chart/DistrictChart.vue";
import WidgetTotal from "@/components/reports/widgetAppeal/WidgetTotal.vue";
import WidgetWait from "@/components/reports/widgetAppeal/WidgetWait.vue";
import WidgetReceive from "@/components/reports/widgetAppeal/WidgetReceive.vue";
import WidgetProcessing from "@/components/reports/widgetAppeal/WidgetProcessing.vue";
import WidgetSuccess from "@/components/reports/widgetAppeal/WidgetSuccess.vue";
import Filter from "@/components/reports/Filter.vue";

import { defineComponent, onMounted, onUpdated, ref } from "vue";
import {
  MenuComponent,
  ToggleComponent,
  DrawerComponent,
} from "@/assets/js/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPage } from "@/core/helpers/checkpage";
export default defineComponent({
  name: "reports-report_all",
  components: {
    WidgetRegister,
    WidgetRegisterProvince,
    WidgetRegisterAmphoe,
    WidgetRegisterProvinceOther,
    GenderChart,
    DistrictChart,
    WidgetTotal,
    WidgetWait,
    WidgetReceive,
    WidgetProcessing,
    WidgetSuccess,
    Filter,
  },
  setup() {
    onMounted(() => {
      checkPage("report_all-list");
      setCurrentPageBreadcrumbs("รายงานสรุป", ["รายงาน"]);
      DrawerComponent.reinitialization();
    });

    const getNewTotal = ref(null);
    const getNewSuccess = ref(null);
    const getNewWait = ref(null);
    const getNewReceive = ref(null);
    const getNewProcessing = ref(null);

    const onFilter = (val) => {
      getNewTotal.value.Filter(val);
      getNewSuccess.value.Filter(val);
      getNewWait.value.Filter(val);
      getNewReceive.value.Filter(val);
      getNewProcessing.value.Filter(val);
    };

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });

    return { onFilter,getNewTotal,getNewSuccess,getNewWait,getNewReceive,getNewProcessing };
  },
});
</script>

<style scoped>
button.Page.Page-active {
  color: white;
}
</style>
