import ApiService from "@/core/services/ApiService";

export default function useReport_all() {

const getWidgetRegister = async (title,value) => {
    return await ApiService.report("report_widget_register",title,value);
  };

  const getWidgetRegisterProvince = async (title,value) => {
    return await ApiService.report("report_widget_register_province",title,value);
  };

  const getWidgetRegisterAmphoe = async (title,value) => {
    return await ApiService.report("report_widget_register_amphoe",title,value);
  };

  const getWidgetRegisterProvinceOther = async (title,value) => {
    return await ApiService.report("report_widget_register_province_other",title,value);
  };


  const getChartGender = async (title,value) => {
    return await ApiService.report("report_chart_gender",title,value);
  };

  const getChartDistrict = async (title,value) => {
    return await ApiService.report("report_chart_district",title,value);
  };

  const getWidgetAppealTotal = async (title,value) => {
    return await ApiService.report("report_widget_appeal_total",title,value);
  };

  const getWidgetAppealWait = async (value) => {
    return await ApiService.report("report_widget_appeal_wait",value);
  };

  const getWidgetAppealReceive = async (title,value) => {
    return await ApiService.report("report_widget_appeal_receive",title,value);
  };

  const getWidgetAppealProcessing = async (title,value) => {
    return await ApiService.report("report_widget_appeal_processing",title,value);
  };

  const getWidgetAppealSuccess = async (title,value) => {
    return await ApiService.report("report_widget_appeal_success",title,value);
  };

  
  

  

  return {
    getWidgetRegister,
    getWidgetRegisterProvince,
    getWidgetRegisterAmphoe,
    getWidgetRegisterProvinceOther,
    getChartGender,
    getChartDistrict,
    getWidgetAppealTotal,
    getWidgetAppealWait,
    getWidgetAppealReceive,
    getWidgetAppealProcessing,
    getWidgetAppealSuccess
  };
}
